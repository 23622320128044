export const cols = [
  {
    label: '公告标题',
    minWidth: '200',
    slotName: 'templateName'
  },
  {
    // slotName: 'stateSlot',
    label: '公告分类',
    minWidth: '200',
    prop: 'categoryName'
  },
  {
    slotName: 'status',
    label: '状态',
    minWidth: '200',
    prop: 'status'
  },
  {
    slotName: 'releaseTimeSlot',
    label: '公告时间',
    minWidth: '200',
    prop: 'releaseTime'
  },
  {
    label: '公告展示方式',
    minWidth: '200',
    prop: 'displayMode',
    formatter(row) {
      return {
        1: '单次弹出',
        2: '每次登录弹出'
      }[row.displayMode]
    }
  }
]
